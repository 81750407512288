import Vue from 'vue'
import restClient from '../../views/lib/restClientNewAPI'
import { cloneDeep, intersection } from 'lodash'
import backendUtils from '../storeUtils/backendUtils'
import utils from '../../appUtils/utils.js'
import workflowTags from '../storeUtils/workflowTags'

const baseURL = 'https://rest-api.' + process.env.VUE_APP_SUBDOMAIN + 'cloud.quantumsimulations.de'

export default {

    namespaced: true,
    state: {
        topicsHierarchy: [],
        currentTopic: {},
        currentProduct: {},
        data: {},
        dataSet: [],
        datas: [],
        schema: {},
        schemas: [],
        process: {},
        processes: [],
        workflow: {},
        workflows: [],
        jobFilter: {
            date: [],
            structure: [],
            state: [],
            workflow: [],
        },
        currentWorkflows: [],
        currentProcesses: [],
        currentInputStructureSchemas: [],
        creditsPerHour: 0.3,
        appBarTitle: '',
        results: [],
        structuresMenu: [],
        showAlert: false,
        alertType: '',
        docsRouteName: '',
        isAuthError: false,
        alertTimeout: process.env.VUE_APP_ALERT_TIMEOUT,
    },
    getters: {

        getSchemasByTag: (state) => (tag) => {
            return state.schemas.filter((e) => e.tags.includes(tag))
        },

        getDatasByTag: (state) => (tag) => {
            let schemaLabels = []
            state.schemas.filter((e) => e.tags.includes(tag))
                .forEach(e => schemaLabels.push(e.label))
            return state.datas.filter(e => schemaLabels.includes(e.hqschema.label))
        },

        getApplicationsForWorkflows: (state) => {
            let tags = []
            state.currentWorkflows.forEach(e => {
                let topicTag = e.tags.find(tag => workflowTags.topics.includes(tag))
                tags.push(topicTag)
            })
            tags = utils.getUniqueArray(tags)
            return state.topicsHierarchy.filter(e => intersection(e.tags, tags).length > 0)
        },

        getStructureTable: (state, getters) => (schemaToFilterWith) => {
            let structures = []
            if (schemaToFilterWith === 'all') {
                structures = getters.getDatasByTag('structure')
            } else {
                structures = state.dataSet.filter(e => e.hqschema.label === schemaToFilterWith)
            }
            let table = []
            for (let structure of structures) {
                let source = backendUtils.getStructureSource(structure, state.processes)
                let tags = backendUtils.getWorkflowTagsForData(structure, state.workflows)
                let products = backendUtils.getProductLabelsByTags(tags, state.topicsHierarchy)
                let item = {
                    label: structure.label,
                    products: products.join(', '),
                    description: structure.description,
                    source: source,
                    createdDate: structure._cdate,
                    _uuid: structure._uuid,
                }
                table.push(item)
            }
            return table
        },

        getJobTable: (state) => (getAll) => {
            let table = []
            let jobs = getAll ? state.processes : state.currentProcesses
            for (let job of jobs) {
                let inputStructures = []
                let structureLabels = []
                let structure_uuids = []
                let schemaLabels = []
                let workflow = 'workflow' in job ? job.workflow.label : ''
                if ('inputs' in job) {
                    inputStructures = backendUtils.getInputStructures(job, state.datas, state.schemas)
                    structureLabels = cloneDeep(inputStructures).map(e => e = e.label)
                    structure_uuids = cloneDeep(inputStructures).map(e => e = e._uuid)
                    schemaLabels = inputStructures.map(e => e = e.hqschema.label)
                }
                let item = {
                    label: job.label,
                    workflow: workflow,
                    description: job.description,
                    structureLabels: structureLabels.length > 0 ? structureLabels.join(", ") : 'deleted',
                    structure_uuids: structure_uuids,
                    inputSchemas: schemaLabels,
                    createdDate: job._cdate,
                    _uuid: job._uuid,
                    state: job.state,
                }
                if (backendUtils.applyJobFilters(item, state.jobFilter)) { table.push(item) }
            }
            return table
        },

        getJobStateCount: (state) => {
            let active = state.processes.filter(e => e.state === 'ACTIVE')
            let failed = state.processes.filter(e => e.state === 'FAILED')
            let externally_terminated = state.processes.filter(e => e.state.includes('EXTERNALLY_TERMINATED'))
            let completed = state.processes.filter(e => e.state === 'COMPLETED')
            return [completed.length, active.length, failed.length, externally_terminated.length]
        },

        getByLabelAndVersion: (state) => ([type, label, version]) => {
            type = backendUtils.getTypePlural(type)
            if (version === 'latest') {
                let items = state[type].filter(e => e.label === label)
                let itemWithLatestVersion = backendUtils.getItemWithLatestVersion(items)
                return state[type].find(e => e.label === itemWithLatestVersion.label
                    && e.version === itemWithLatestVersion.version)
            }
            return state[type].find(e => e.label === label && e.version === version)
        },

        getById: (state) => ([type, id]) => {
            type = backendUtils.getTypePlural(type)
            let target = state[type]
            return target.find(e => e._uuid === id)
        },

        getAll: (state) => (type) => {
            type = backendUtils.getTypePlural(type)
            return state[type]
        },

        getFilteredData: (state) => ([type, data]) => {
            return backendUtils.filterDataForRelevance(
                type, data, cloneDeep(state.workflows)
            )
        },

        getStructureLabelsForProduct: (state) => (productLabel) => {
            let structureLabels = []
            let workflowsForProduct = backendUtils.getWorkflowsForProduct(productLabel, state.workflows, state.topicsHierarchy)
            let workflowInputs = backendUtils.getWorkflowInputs(workflowsForProduct)
            let labels = new Set([...workflowInputs.map(e => e = e.hqschema.label)])
            for (let label of labels) {
                try {
                    let schema = state.schemas.find(e => e.label === label)
                    if (schema.tags.includes('structure')) {
                        structureLabels.push(schema.label)
                    }
                } catch (error) {
                    console.log('schema not found', error)
                }
            }
            return structureLabels
        },

        getSubCategories: (state) => {
            let standardCategoryKeys = ['name', 'title', 'tags']
            let subCategories = []
            for (let key of Object.keys(state.currentProduct)) {
                if (!standardCategoryKeys.includes(key) && state.currentProduct[key].length > 0) {
                    let categoryName = utils.convertCamelCase(key, 'kebabCase')
                    let subCategory = backendUtils.getAppStructureObject(
                        categoryName, workflowTags[key])
                    subCategory.label = key
                    subCategory.items = state.currentProduct[subCategory.label]
                    subCategories.push(subCategory)
                }
            }
            return subCategories
        },

        getProductsForMenu: (state) => {
            let products = []
            let menuItems = []
            for (let topic of state.topicsHierarchy) {
                products.push(...topic.products)
            }
            for (let product of products) {
                let menuItem = {
                    path: { name: utils.getProductDashboardRouteName(product.name) },
                    title: product.title,
                    disabled: false,
                    dataCy: 'create_' + utils.convertToUnderscoreNotation(product.name) + '_job',
                }
                menuItems.push(menuItem)
            }
            return menuItems
        }
    },

    mutations: {

        set(state, [type, item]) {
            Vue.set(state, type, item)
        },

        setCurrentProduct(state, name) {
            let currentProduct = state.currentTopic.products.find((e) => e.name === name)
            Vue.set(state, 'currentProduct', currentProduct)
        },

        prepareResults(state, results) {
            let resultItems = []
            for (let result of results) {
                let def = result.schema.definition
                let resultItem = []
                for (let [key, value] of Object.entries(result.data)) {
                    if (key.charAt(0) !== '_'
                        && key !== 'hqschema'
                        && Object.keys(result.data).includes(key)) {
                        let type = def[key].type ? def[key].type : ''
                        let unit = def[key].meta.unit ? def[key].meta.unit : ''
                        if (
                            ('check_with' in def[key] &&
                                def[key].check_with === 'svg_base64') || key.includes('svg')
                        ) {
                            type = 'svg'
                            value = decodeURIComponent(escape(atob(value)))
                        } else if (key === 'log') {
                            type = 'array'
                            value = backendUtils.formatLog(value)
                        }
                        key = utils.formatLabel(key)
                        resultItem.push({ name: key, value: value, type: type, unit: unit })
                    }
                }
                resultItems.push(resultItem)
            }
            resultItems = backendUtils.setItemToEndOfObjectArray(resultItems, 'name', 'Log')
            Vue.set(state, 'results', resultItems)
        },

        setCurrentWorkflowsByStructure(state, structureName) {
            let currentWorkflows = backendUtils.findWorkflowsByStructureName(structureName, state.workflows)
            Vue.set(state, 'currentWorkflows', currentWorkflows)
        },

        setInputStructureSchemasForCurrentWorkflows(state) {
            let labels = []
            for (let workflow of cloneDeep(state.currentWorkflows)) {
                for (let key of Object.keys(workflow.inputs)) {
                    labels.push(workflow.inputs[key].hqschema.label)
                }
            }
            let schemas = state.schemas.filter(e => labels.includes(e.label))
            let currentInputStructureSchemas = schemas.filter(e => e.tags.includes('structure'))
            Vue.set(state, 'currentInputStructureSchemas', currentInputStructureSchemas)
        },

        setCurrentProcesses(state) {
            let labels = []
            let currentProcesses = []
            for (let workflow of cloneDeep(state.currentWorkflows)) {
                labels.push(workflow.label)
            }
            for (let process of state.processes) {
                if (labels.includes(process.workflow.label)) {
                    currentProcesses.push(process)
                }
            }
            Vue.set(state, 'currentProcesses', currentProcesses)
        },

        setCurrentTopic(state, [name, isProduct]) {
            let currentTopic = {}
            if (name !== '' && !isProduct) {
                currentTopic = state.topicsHierarchy.find(topic => topic.name === name)
            } else if (name !== '' && isProduct) {
                for (let topic of state.topicsHierarchy) {
                    for (let product of topic.products) {
                        if (product.name === name) {
                            currentTopic = topic
                        }
                    }
                }
            }
            Vue.set(state, 'currentTopic', currentTopic)
        },

        createAppStructure(state) {
            let topics = []
            let categories = Object.keys(workflowTags)
            for (let category of categories) {
                topics = backendUtils.addCategory(topics, state.workflows, category)
            }
            Vue.set(state, 'topicsHierarchy', topics)
        },

        setFilter(state, [value, type]) {
            state.jobFilter[type] = value
        },

        resetDateFilter(state) {
            state.jobFilter.date.splice(0, state.jobFilter.date.length)
        },

        activateAlert: (state, type) => {
            state.showAlert = true
            state.alertType = type
        },

        resetAlert: (state) => {
            state.showAlert = false
            state.alertType = ''
        },

        add(state, [type, config]) {
            state[type].push(config)
        },

        applyChanges(state, [type, id, config]) {
            type = backendUtils.getTypePlural(type)
            let target = state[type]
            let index = target.indexOf(target.find(e => e._uuid === id))
            Vue.set(target, index, config)
        },

        remove(state, [type, id]) {
            type = backendUtils.getTypePlural(type)
            let index = state[type].indexOf(state[type].find(e => e._uuid === id))
            Vue.delete(state[type], index)
        },

        resetBackendStore(state) {
            state.datas.splice(0, state.datas.length)
            state.processes.splice(0, state.processes.length)
            state.dataSet.splice(0, state.dataSet.length)
        },

    },

    actions: {
        async loadById({ commit }, [type, id, label = '', version = '']) {
            let result = {}
            try {
                if (label !== '' && version !== '') {
                    result = await restClient.get(
                        `${baseURL}/${type}?hqschema-label=${label}&hqschema-version=${version}/${id}`)
                } else {
                    result = await restClient.get(
                        `${baseURL}/${type}/${id}`)
                }
                if (Array.isArray(result)) {
                    commit('set', [type, result.data[type][0]])
                    return result.data[type]
                } else {
                    commit('set', [type, result.data[type]])
                    return result.data[type] 
                }
            } catch (e) {
                if (e.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                console.log(e)
                throw e
            }
        },

        async load({ commit, getters }, [type, label = '', version = '']) {
            let result = {}
            try {
                if (type === 'schema' || (label === '' || version === '')) {
                    result = await restClient.get(
                        `${baseURL}/${type}`)
                    if (result.data[type].length > 0) {
                        let filteredData = getters.getFilteredData([type, result.data[type]])
                        type = backendUtils.getTypePlural(type)
                        commit('set', [type, filteredData])
                    }
                } else {
                    result = await restClient.get(
                        `${baseURL}/${type}?hqschema-label=${label}&hqschema-version=${version}`)
                    if (result.data[type]) {
                        commit('set', [type, result.data[type]])
                    }
                    return result.data[type]
                }

            } catch (e) {
                if (e.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                console.log(e)
                throw e
            }
        },

        async loadAll({ commit }, [type]) {
            let result = {}
            try {
                result = await restClient.get(`${baseURL}/${type}`)
                if (result.data[type].length > 0) {
                    let resultArray = result.data[type]
                    type = backendUtils.getTypePlural(type)
                    commit('set', [type, resultArray])
                }
            } catch (e) {
                if (e.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                console.log(e)
                throw e
            }
        },

        async loadMultipleDataSets({ commit }, schemas) {
            let collectedDatas = []
            for (let schema of schemas) {
                try {
                    let result = await restClient.get(
                        `${baseURL}/data?hqschema-label=${schema.label}&hqschema-version=${schema.version}`)
                    if (result.data.data.length > 0) {
                        collectedDatas.push(...result.data.data)
                    }
                    commit('set', ['dataSet', collectedDatas])
                } catch (error) {
                    if (error.request.status === '401') {
                        commit('set', ['isAuthError', true])
                    }
                    console.log(error)
                    throw error
                }
            }
        },

        async update({ commit }, [type, id, config]) {
            try {
                await restClient.patch(`${baseURL}/${type}/${id}`, config)
                commit('applyChanges', [type, id, config])
            } catch (e) {
                if (e.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                console.log(e)
                throw e
            }
        },

        async create({ commit }, [type, config]) {
            let result = {}
            try {
                result = await restClient.post(`${baseURL}/${type}`, config)
                let stateType = backendUtils.getTypePlural(type)
                if (Array.isArray(result.data[stateType])) {
                    commit('add', [stateType, result.data[type][0]])
                } else {
                    commit('add', [stateType, result.data[type]])
                }
                return result.data
            } catch (error) {
                console.log(error)
                if (error.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },

        async createPredefined({ commit }, [type, config]) {
            let result = {}
            try {
                result = await restClient.put(`${baseURL}/${type}/${config._uuid}`, config)
                let stateType = backendUtils.getTypePlural(type)
                if (Array.isArray(result.data[stateType])) {
                    commit('add', [stateType, result.data[type][0]])
                } else {
                    commit('add', [stateType, result.data[type]])
                }
                return result.data
            } catch (error) {
                console.log(error)
                if (error.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                throw (error)
            }
        },

        async delete({ commit }, [type, id]) {
            try {
                await restClient.delete(`${baseURL}/${type}/${id}`)
                commit('remove', [type, id])
            } catch (e) {
                if (e.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                console.log(e)
                throw e
            }
        },

        async cancelProcess(commit, id) {
            let data = { state: 'ABORTED' }
            try {
                await restClient.patch(`${baseURL}/process/${id}`, data)
            } catch (e) {
                if (e.request.status === '401') {
                    commit('set', ['isAuthError', true])
                }
                console.log(e)
                throw e
            }
        },
    }
}
