<template>
  <div class="page-margin-left page-margin-top-ui page-margin-right">
    <v-card>
      <v-row>
        <!-- Heading -->
        <Header
          class="ml-3"
          :headerTitle="mainTitle"
          :hasMenu="hasMenu"
          :menuList="menuList"
        ></Header>
        <!-- structure loading -->
        <v-spacer></v-spacer>
        <div class="mt-3 mr-5"><LoadDropdown :isDirty="isDirty" /></div>
      </v-row>
      <SuccessAlert
        data-cy="alert"
        v-if="showAlert"
        :type="alertType"
        :message="alertMessage"
      />
      <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>

      <v-form v-else ref="form" v-model="valid" class="mx-5 mb-5">
        <FormField
          v-for="(node, index) in nodes"
          :key="index"
          :valuePath="[node.name]"
          :data="node"
        >
        </FormField>
      </v-form>
      <v-row v-if="showFormInfo">
        <v-col cols="7" />
        <v-col cols="5">
          <v-sheet
            class="text font-weight-light error--text"
            v-for="(element, index) in emptyFormElements"
            :key="index"
            >{{ element }}</v-sheet
          >
        </v-col>
      </v-row>
      <v-card-actions>
        <!-- actions -->
        <v-spacer></v-spacer>
        <v-btn
          data-cy="clear"
          class="mt-10 mr-2 mb-2"
          rounded
          text
          color="darkGrey"
          @click="clear"
          >clear</v-btn
        >
        <v-btn
          v-if="!valid"
          data-cy="validate"
          class="mt-10 mr-2 mb-2"
          rounded
          dark
          depressed
          color="lightGrey"
          @mouseover="triggerFormInfo"
          >submit</v-btn
        >
        <v-btn
          v-else
          data-cy="submit"
          class="mt-10 mr-2 mb-2"
          rounded
          :dark="valid"
          depressed
          color="success"
          :disabled="!valid"
          @click="submit"
          >submit</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfigurationInfo
      ref="info"
      v-bind:copyData="copyData"
      v-bind:user="user"
    />
  </div>
</template>

<script>
const Header = () => import('../../components/general/Header.vue')
const FormField = () => import('../../components/dynamicForms/FormField.vue')
const ConfigurationInfo = () =>
  import('../../components/general/ConfigurationInfo.vue')
const LoadDropdown = () => import('../../components/general/LoadDropdown.vue')
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../appUtils/utils'
const SuccessAlert = () => import('../../components/general/SuccessAlert.vue')

export default {
  components: {
    Header,
    FormField,
    ConfigurationInfo,
    LoadDropdown,
    SuccessAlert,
  },

  data() {
    return {
      /* Header */
      hasMenu: false,
      menuList: [],
      /* form validation */
      valid: false,
      /* additional validation info on hover */
      emptyFormElements: [],
      showFormInfo: false,
      /* ConfigurationInfo */
      copyData: {},
      user: {},
      /* LoadDropdown */
      isDirty: false,
      /* SuccessAlert */
      showAlert: false,
      alertType: '',
      alertMessage: '',
      /* skeleton loader */
      loading: false,
      /* find schema for rendering UI */
      fullUiSchema: {},
      schemasAlreadyReloaded: false,
    }
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'normalizeSchema',
      'createUserInputFromSchema',
      'createUserInputFromDataNode',
      'wipeForm',
      'set',
    ]),

    ...mapMutations('backend', { setBackendStore: 'set' }),

    ...mapActions('backend', [
      'load',
      'loadById',
      'loadMultipleDataSets',
      'create',
      'delete',
      'update',
    ]),

    clear() {
      this.setBackendStore(['data', {}])
      this.$refs.form.resetValidation()
      this.createUserInputFromSchema()
      this.set(['edit', false])
    },

    async submit() {
      this.showAlert = false
      let data = this.getDataNode
      try {
        if (this.edit) {
          await this.update(['data', this.data._uuid, data])
        } else {
          await this.create(['data', data])
        }
        let schemas = this.schemas.filter(
          (e) => e.label === this.$route.name.replaceAll('-', '_')
        )
        this.clear()
        await this.loadMultipleDataSets(schemas)
        this.prepareAlert('success')
      } catch (error) {
        let validationMessage = ''
        if ('response' in error) {
          validationMessage = JSON.stringify(
            error.response.data.message,
            null,
            4
          )
        } else {
          console.log('error', error)
        }
        this.prepareAlert('error', validationMessage)
      }
      this.set(['edit', false])
    },

    prepareAlert(type, validationMessage = '') {
      this.alertType = type
      if (type === 'success') {
        this.alertMessage = this.edit
          ? 'Edited successfully'
          : 'Saved successfully'
      } else if (type === 'error') {
        let uiMessage = this.edit ? 'Editing failed ' : 'Saving failed '
        this.alertMessage = uiMessage + validationMessage
      }
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },

    async findSchema() {
      let uiSchema = {}
      if (this.edit) {
        uiSchema = await this.getByLabelAndVersion([
          'schema',
          this.data.hqschema.label,
          this.data.hqschema.version,
        ])
      } else {
        uiSchema = await this.getByLabelAndVersion([
          'schema',
          utils.getLabelFromPathName(this.$route.name),
          'latest',
        ])
      }
      try {
        this.fullUiSchema = await this.loadById(['schema', uiSchema._uuid])
        this.schemasAlreadyReloaded = false
      } catch (error) {
        console.log('error', error)
        if (error.response.status === 404 && !this.schemasAlreadyReloaded) {
          await this.load(['schema'])
          this.schemasAlreadyReloaded = true
          this.findSchema()
        }
      }
    },

    triggerFormInfo() {
      this.showFormInfo = true
      if (!this.valid && this.emptyFormElements.length === 0) {
        this.emptyFormElements.push(
          'Please fill out every required form field!'
        )
        this.$refs.form.validate()
      }
    },

    async prepareFormRendering() {
      await this.findSchema()
      await this.normalizeSchema([this.fullUiSchema, false])
      if (this.edit) {
        await this.createUserInputFromDataNode(this.data)
      } else {
        await this.createUserInputFromSchema()
      }
    },

    async loadScopeData() {
      if (this.schemas.length === 0) {
        await this.load(['schema'])
      }
      if (this.workflows.length === 0) {
        await this.load(['workflow'])
      }
    },
  },

  computed: {
    ...mapState('dynamicForms', {
      normalizedSchema: (state) => state.normalizedSchema,
      originalSchema: (state) => state.originalSchema,
      edit: (state) => state.edit,
    }),

    ...mapState('backend', {
      schema: (state) => state.schema,
      schemas: (state) => state.schemas,
      datas: (state) => state.datas,
      data: (state) => state.data,
      workflows: (state) => state.workflows,
      showAlertState: (state) => state.showAlert,
      alertTypeState: (state) => state.alertType,
      alertTimeout: (state) => state.alertTimeout,
    }),

    ...mapGetters('dynamicForms', ['getDataNode']),

    ...mapGetters('backend', ['getByLabelAndVersion']),

    nodes() {
      return this.normalizedSchema.nodes
    },

    mainTitle(){
        return utils.formatLabel(this.$route.name) + ' Configuration'
          
    },
  },

  watch: {
    showAlertState() {
      if (this.showAlertState) {
        if (this.alertTypeState === 'success') {
          this.alertMessage = 'Deleted successfully'
        } else {
          this.alertMessage = 'Deletion failed'
        }
        this.alertType = this.alertTypeState
        this.showAlert = true
      } else {
        this.showAlert = false
        this.alertMessage = ''
        this.alertType = ''
      }
    },

    valid() {
      if (this.valid) {
        this.showFormInfo = false
        this.emptyFormElements = []
      }
    },
  },

  async created() {
    this.loading = true
    await this.loadScopeData()
    try {
      await this.prepareFormRendering()
    } catch (error) {
      await this.load(['schema'])
      await this.prepareFormRendering()
    }
    this.loading = false
  },

  async destroyed() {
    this.set(['edit', false])
  },
}
</script>

<style scoped></style>
