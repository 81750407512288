<template>
  <div class="page-margin-left page-margin-top-app-dashbord page-margin-right">
    <v-row class="mt-15">
      <v-col cols="12">
        <!-- menu-cards -->
        <v-card>
          <!-- Heading -->
          <Header
            :headerTitle="currentProduct.title + ' Dashboard'"
            :hasMenu="false"
          ></Header>
          <!-- sub-category cards -->
          <v-card-text>
            <v-row v-if="level === 'product'">
              <v-col
                md="3"
                sm="5"
                v-for="category in getSubCategories"
                :key="category.name"
              >
                <v-hover v-slot="{ hover }" close-delay="200">
                  <v-card
                    class="{ 'on-hover': hover } text sub-category-card"
                    :elevation="hover ? 16 : 2"
                    @click="prepareSubCategory(category)"
                  >
                    <v-responsive :aspect-ratio="1 / 1">
                      <v-card-title class="body-1 medium-weight-text text">{{
                        category.title
                      }}</v-card-title>
                    </v-responsive>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <!-- selected sub-category card -->
            <v-row v-if="level === 'category'">
              <v-col md="3" sm="4">
                <v-card class="text sub-category-card">
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-card-title
                      class="body-1 medium-weight-text text title"
                      >{{ currentSubCategory.title }}</v-card-title
                    >
                  </v-responsive>
                </v-card>
              </v-col>
              <!-- sub-category item selection -->
              <v-col
                md="3"
                sm="4"
                v-for="item in currentSubCategoryItems"
                :key="item.title"
              >
                <v-hover v-slot="{ hover }" close-delay="200">
                  <v-card
                    class="{ 'on-hover': hover } text sub-category-item-card"
                    :elevation="hover ? 16 : 2"
                    @click="prepareSubCategoryItem(item)"
                  >
                    <v-responsive :aspect-ratio="1 / 1">
                      <v-card-title
                        class="body-1 medium-weight-text text title"
                        >{{ item.title }}</v-card-title
                      >
                    </v-responsive>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-row>
              <!-- selected sub-category-item card -->
              <v-col md="3" sm="5" v-if="level === 'subCategory'">
                <v-card class="text sub-category-item-card">
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-card-title
                      class="body-1 medium-weight-text text title"
                      >{{ currentSubCategoryItem.title }}</v-card-title
                    >
                  </v-responsive>
                </v-card>
              </v-col>
              <!-- go-back button -->
              <v-spacer></v-spacer>
              <v-col v-if="level === 'category' || level === 'subCategory'">
                <v-btn icon @click="goBack" outlined
                  ><v-icon>mdi-arrow-left-bold-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- jobs table -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <Header
            :hasMenu="hasMenu"
            :headerTitle="headerTitle"
            :icon="icon"
            :href="href"
            :menuList="menuList"
          ></Header>
          <!-- e2e testing setup creation -->
          <CreateTestingSetup />
          <DynamicJobsTable :loadTable="loadTable" />
        </v-card>
      </v-col>
    </v-row>
    <!-- create job form -->
    <v-row>
      <v-col cols="12" id="create-job">
        <CreateDynamicJob @loadTable="triggerTableReload" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const CreateDynamicJob = () =>
  import('../../components/jobs/CreateDynamicJob.vue')
const DynamicJobsTable = () =>
  import('../../components/jobs/DynamicJobsTable.vue')
const Header = () => import('../../components/general/Header.vue')
const CreateTestingSetup = () =>
  import('../../components/general/CreateTestingSetup.vue')
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../appUtils/utils.js'

export default {
  components: {
    DynamicJobsTable,
    CreateDynamicJob,
    Header,
    CreateTestingSetup,
  },

  data() {
    return {
      currentSubCategory: {},
      currentSubCategoryItem: {},
      level: '',
      loadTable: false,
      /* Header */
      hasMenu: true,
      icon: 'mdi-plus',
      menuList: [],
      href: '#create-job',
    }
  },

  methods: {
    ...mapMutations('backend', [
      'setCurrentTopic',
      'setCurrentProcesses',
      'setInputStructureSchemasForCurrentWorkflows',
      'setCurrentProduct',
      'set',
    ]),
    ...mapActions('backend', ['load']),

    triggerTableReload() {
      this.loadTable = !this.loadTable
    },

    goBack() {
      if (this.level === 'subCategory') {
        this.level = 'category'
      }
      if (this.level === 'category') {
        this.level = 'product'
      }
    },

    prepareSubCategory(category) {
      this.currentSubCategory = category
      this.level = 'category'
      this.set([
        'currentWorkflows',
        utils.filterForMatchingTags(this.workflows, [
          this.currentProduct.tags,
          category.tags,
        ]),
      ])
      this.setCurrentProcesses()
      this.setDocsRouteName()
    },

    prepareSubCategoryItem(item) {
      this.level = 'subCategory'
      this.currentSubCategoryItem = item
      this.set([
        'currentWorkflows',
        utils.filterForMatchingTags(this.workflows, [
          this.currentProduct.tags,
          item.tags,
        ]),
      ])
      this.setCurrentProcesses()
    },

    setDocsRouteName() {
      if (this.$route.name.includes('-dashboard')) {
        this.set([
          'docsRouteName',
          utils.addPartToDocsRoute(this.docsRouteName, 'workflows'),
        ])
      } else {
        this.set(['docsRouteName', ''])
      }
    },
  },

  computed: {
    ...mapState('backend', {
      currentTopic: (state) => state.currentTopic,
      workflows: (state) => state.workflows,
      currentWorkflows: (state) => state.currentWorkflows,
      schemas: (state) => state.schemas,
      currentProcesses: (state) => state.currentProcesses,
      currentInputStructureSchemas: (state) =>
        state.currentInputStructureSchemas,
      currentProduct: (state) => state.currentProduct,
      docsRouteName: (state) => state.docsRouteName,
      topicsHierarchy: (state) => state.topicsHierarchy,
    }),

    ...mapGetters('backend', ['getSubCategories']),

    /* Header component */
    headerTitle() {
      if (this.$route.name.includes('structure-dashboard')) {
        return 'Jobs with ' + this.labelFromPathName + ' Structure input'
      } else if (this.$route.name === 'jobs') {
        return 'Jobs Dashboard'
      } else {
        let name = this.labelFromPathName
        name = utils.formatLabel(name)
        return name + ' Jobs'
      }
    },

    labelFromPathName() {
      return utils.getLabelFromPathName(this.$route.name)
    },

    currentSubCategoryItems() {
      return this.currentProduct[this.currentSubCategory.label]
    },
  },

  async created() {
    this.setCurrentTopic([this.labelFromPathName, true])
    await this.setCurrentProduct(this.labelFromPathName)
    if (this.schemas.length === 0) {
      await this.load(['schema'])
    }
    this.set([
      'currentWorkflows',
      utils.filterForMatchingTags(this.workflows, this.currentProduct.tags),
    ])
    await this.setCurrentProcesses()
    this.level = 'product'
    this.set(['appBarTitle', this.currentProduct.title])
    await this.setInputStructureSchemasForCurrentWorkflows()
  },
}
</script>

<style scoped>
.sub-category-card {
  max-height: 150px;
  max-width: 180px;
  min-height: 150px;
  min-width: 180px;
  background-image: linear-gradient(#9ac1c9, white);
}

.sub-category-item-card {
  max-height: 75px;
  max-width: 180px;
  min-height: 75px;
  min-width: 180px;
  background-image: linear-gradient(#bd847f, white);
}
</style>
