<template>
  <div class="page-margin-left page-margin-top-app-dashbord page-margin-right">
    <v-row class="mt-15">
      <v-col cols="12">
        <!-- menu-cards -->
        <v-card>
          <!-- Heading -->
          <Header :headerTitle="mainTitle" :hasMenu="false"></Header>
          <v-card-text>
            <v-list class="text" rounded outlined dense>
              <v-list-item
                v-for="item in getApplicationsForWorkflows"
                :key="item.title"
              >
                <v-list-item-icon>
                  <v-icon>mdi-rocket-launch-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <Header
            :hasMenu="hasMenu"
            :headerTitle="jobTableTitle"
            :icon="icon"
            :href="href"
            :menuList="menuList"
          />
          <DynamicJobsTable :loadTable="loadTable" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" id="create-job">
        <CreateDynamicJob @loadTable="triggerTableReload" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const CreateDynamicJob = () =>
  import('../../components/jobs/CreateDynamicJob.vue')
const DynamicJobsTable = () =>
  import('../../components/jobs/DynamicJobsTable.vue')
const Header = () => import('../../components/general/Header.vue')
import utils from '../../appUtils/utils.js'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DynamicJobsTable,
    CreateDynamicJob,
    Header,
  },

  data() {
    return {
      loadTable: false,
      /* Header */
      hasMenu: true,
      icon: 'mdi-plus',
      menuList: [],
      href: '#create-job',
    }
  },

  methods: {
    ...mapMutations('backend', [
      'setCurrentWorkflowsByStructure',
      'setCurrentProcesses',
      'set',
    ]),
    ...mapActions('backend', ['load']),

    triggerTableReload() {
      this.loadTable = !this.loadTable
    },
  },

  computed: {
    ...mapState('dynamicForms', {
      currentStructureCard: (state) => state.currentStructureCard,
    }),

    ...mapState('backend', {
      workflows: (state) => state.workflows,
      currentWorkflows: (state) => state.currentWorkflows,
    }),

    ...mapGetters('backend', ['getApplicationsForWorkflows']),

    jobTableTitle() {
      let structureName = this.schemaLabelFromPathName
      structureName = utils.formatLabel(structureName)
      return 'Jobs With ' + structureName + ' Structures'
    },

    mainTitle() {
      return 'Applications For ' + this.sructureName + ' Structure'
    },

    sructureName() {
      return utils.formatLabel(this.schemaLabelFromPathName)
    },

    schemaLabelFromPathName(){
        return utils.getLabelFromPathName(this.$route.name)
    },
  },

  async created() {
    if (this.workflows.length === 0) {
      await this.load(['workflow'])
    }
    this.setCurrentWorkflowsByStructure(
      this.schemaLabelFromPathName
    )
    this.setCurrentProcesses()
    this.set(['docsRouteName', ''])
  },
}
</script>

<style scoped>
.method-card {
  max-height: 200px;
  max-width: 200px;
  min-height: 200px;
  min-width: 200px;
  background-image: linear-gradient(#9ac1c9, white);
}

.sub-method-card {
  margin: auto;
  max-height: 100px;
  max-width: 200px;
  min-height: 100px;
  min-width: 200px;
  background-image: linear-gradient(#bd847f, white);
}
</style>
