<template>
  <div
    id="jobs-dashboard"
    class="page-margin-left page-margin-top mb-15 page-margin-right"
  >
    <v-card>
      <SecondaryAppBar v-bind:barColor="barColor" :appBarTitle="appBarTitle" />
      <Header
        :headerTitle="headerTitle + ' Of ' + process.label"
        :icon="icon"
        :hasMenu="hasMenu"
        :menuList="menuList"
        :event="event"
        @download="download"
      >
      </Header>

      <v-card-text v-for="result in results" :key="result.label">
        <v-row v-for="item in result" :key="item.name" class="ml-3">
          <h3 class="mr-5 text" v-if="item.name !== 'Log'">{{ item.name }}:</h3>
          <!-- display logfile -->
          <div v-if="item.name === 'Log'">
            <v-btn
            data-cy="show_logfile"
              small
              depressed
              text
              class="text text-lowercase mt-2 ml-n3"
              color="primary"
              @click="showLogfile = !showLogfile"
              >show logfile
              <v-icon right mt-3 v-if="!showLogfile"> mdi-chevron-down </v-icon>
              <v-icon right mt-3 v-else> mdi-chevron-up </v-icon></v-btn
            >
            <v-sheet class="text mx-6" v-if="showLogfile">
              <p v-for="paragraph in item.value" :key="paragraph">
                {{ paragraph }}
              </p>
            </v-sheet>
          </div>
          <!-- display svg -->
          <div v-else-if="item.type === 'svg'" v-html="item.value"></div>
          <!-- display other results -->
          <v-sheet v-else class="darkGrey--text">{{ item.value }} {{ item.unit }}</v-sheet>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
const Header = () => import('../components/general/Header.vue')
const SecondaryAppBar = () => import('../components/general/SecondaryAppBar')
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    SecondaryAppBar,
  },

  data() {
    return {
      headerTitle: 'Results',
      hasMenu: true,
      menuList: [],
      barColor: 'white',
      appBarTitle: 'Results',
      icon: 'mdi-cloud-download-outline',
      event: 'download',
      showLogfile: false,
    }
  },

  methods: {
    download() {
      var element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(this.results))
      )
      element.setAttribute('download', 'result.json')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
  },

  computed: {
    ...mapState('backend', {
      process: (state) => state.process,
      results: (state) => state.results,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
